.content-css span {
  color: #ffffffb3;
  font-size: 13px;
  line-height: 22px;
  padding-bottom: 10px;
  font-weight: 400;
  color: #878c9f;
  text-align: left;
}

.thingsToKnow h6{
  text-align:left;
  color:white;
  font-size: 18px;
}

.thingsToKnow .listing-features a{
  font-size: 14px !important;
}

.thingsToKnow li{
  float: none;
}

.thingsToKnow .listing-features{
  margin-top: 15px;
}

.thingsToKnow .listing-features .cancellationPolicyTitle{
  font-size: 16px !important;
}
