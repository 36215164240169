.override-select-edit .css-1s2u09g-control {
    background-color: #1b182b !important;
    min-height: 50px !important;
    border-color: #605e6b;
}

.override-select-edit .css-1pahdxg-control {
    background-color: #1b182b !important;
    min-height: 50px !important;
    border-color: #605e6b;
}

.override-select-edit .css-319lph-ValueContainer .css-qc6sy-singleValue {
    color: #fff !important;
}

.override-select-edit .css-319lph-ValueContainer .css-1pndypt-Input {
    color: #fff !important;
}

.override-select-edit .css-14el2xx-placeholder {
    color: #fff !important;
}


.override-select-edit {
    color: black !important;
}

.css-1hb7zxy-IndicatorsContainer .css-1okebmr-indicatorSeparator {
    background-color: transparent;
}