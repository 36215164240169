.main_p_and_t{
    background: #1b182b;
}

body{
    background: #1b182b;
}

.container_p_and_t {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin-top: 8rem;
    text-align: center;
}

.main_tag_for_p_and_t{
    color: white;
    width: 100%;
    text-align: center;
    font-size: 35px;
    font-weight: bold;
}

.main_p_data {
    color: white;
    font-weight: 400;
    font-size: 18px;
}