@import "primereact/resources/themes/vela-blue/theme.css";
@import "primereact/resources/primereact.min.css";
@import "primeicons/primeicons.css";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
#dialog-content {
  background-image: url("https://staywobucket.s3.us-west-1.amazonaws.com/Monsoon-Flooding-Crisis-ready-before-ending-Corona.jpeg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  opacity: 0.9;
}
.carousel .thumb {
  height: 8vh;
}

.p-inputtext {
  margin: 0;
  width: 100%;
  height: 100%;
  background: none;
  border: 1px solid rgba(255, 255, 255, 0.3);
}
.p-inputtext:enabled:hover {
  border-color: #0095ff;
}

.p-inputtext:enabled:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 1px #0095ff;
  border-color: #0095ff;
}

.section-title {
  /* margin-bottom: 0px !important; */
}

.main-search-input {
  padding: 10px !important;
}

.p-multiselect-panel {
  z-index: 1200 !important;
}

::-webkit-scrollbar {
  width: 7px;
}

::-webkit-scrollbar-track {
  background-color: #1b182b;
  border-radius: 7px;
}

::-webkit-scrollbar-thumb {
  background-color: #0089eb;
  border-radius: 7px;
}

.p-multiselect {
  display: inline-flex;
  position: relative;
  -webkit-user-select: none;
  user-select: none;
  width: 100%;

  background: none;
  text-align: left;
  border: 1px solid rgba(255, 255, 255, 0.3);
}

.p-autocomplete {
  width: 100%;
  background: none;
}

.p-autocomplete-items {
  text-align: initial;
}

.p-autocomplete-panel {
  z-index: 1200 !important;
}

/* media queries */
@media (max-width: 576px) {
  .p-multiselect {
    max-width: 358px;
  }
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link {
  background: none;
}

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  background-color: #0095ff;
}
.p-paginator .p-paginator-pages .p-paginator-page {
  color: #0095ff;
}
.pi-angle-double-left:before,
.pi-angle-right:before,
.pi-angle-left:before,
.pi-angle-double-right:before {
  color: #0095ff;
}

.p-paginator .p-dropdown {
  background-color: #0095ff;
  /* border-color: rgba(255, 255, 255, 0.1); */
}

.p-paginator {
  border: 0;
}

.p-dropdown {
  background: none;
  border: 1px solid rgba(255, 255, 255, 0.3);
}

.p-dropdown-panel .p-dropdown-header .p-dropdown-filter {
  padding-right: 0;
  margin-right: 0;
}

.p-dropdown-panel .p-dropdown-items {
  background-color: white;
}

.p-dropdown-panel
  .p-dropdown-items
  .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
  background-color: #f1f9ff;
  color: black;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
  background-color: #0095ff;
  color: black;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-empty-message {
  color: black;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  color: black;
}

.p-paginator .p-paginator-first,
.p-button:disabled {
  opacity: 1;
}

@media screen and (max-width: 320px) {
  .css-18mxp5y-MuiGrid-root {
    justify-content: center !important;
  }
}

.p-datepicker table td.p-datepicker-today > span {
  border-radius: 0px;
}

.css-ry863c-MuiAutocomplete-listbox .MuiAutocomplete-option.Mui-focused {
  background-color: #0095ff !important;
}
.css-ry863c-MuiAutocomplete-listbox .MuiAutocomplete-option {
  background-color: #ffffff !important;
  color: black;
}
.dropdown-demo .p-dropdown {
  width: 14rem;
}

.dropdown-demo .country-item-value img.flag {
  width: 17px;
}
/* .css-ym28l4-MuiFormLabel-root-MuiInputLabel-root {
  color: #ffffff!important;
  font-family: sans-serif;
  font-weight: 700!important;
  font-size: 1.3rem!important;
  line-height: 1.4375em;
  letter-spacing: 0.00938em;
  padding: 0;
  position: relative;
  display: block;
  transform-origin: top left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(100% - 24px);
  position: absolute;
  left: 0;
  top: 0;
  -webkit-transform: translate(14px, 16px) scale(1);
  -moz-transform: translate(14px, 16px) scale(1);
  -ms-transform: translate(14px, 16px) scale(1);
  transform: translate(14px, 16px) scale(1);
  -webkit-transition: color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,-webkit-transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,max-width 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
  transition: color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,max-width 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
  z-index: 1;
  pointer-events: none;
} */

.cancelActive {
  color: #0095ff !important;
  border: 1px solid #0095ff !important;
  border-radius: 5px !important;
}

.css-9425fu-MuiOutlinedInput-notchedOutline {
  text-align: left;
  position: absolute;
  bottom: 0;
  right: 0;
  top: -5px;
  left: 0;
  margin: 0;
  padding: 0 8px;
  pointer-events: none;
  border-radius: inherit;
  border-style: solid;
  border-width: 1px;
  overflow: hidden;
  min-width: 0%;
  border-color: rgba(255, 255, 255, 0.23) !important;
}

.more-hidden_wrap {
  display: block;
}

.content-markup {
  font-size: 16px;
  color: #fff;
}

.floodRelief {
  background: red !important;
}

.p-datepicker {
  z-index: 1301 !important;
}

.css-nq57ey-MuiModal-root-MuiDialog-root {
  z-index: 1000 !important;
}

/* ** Cantact Form of Contact Page Design Starts from here */

.form-container {
  width: 100%;
  padding: 50px 200px;
  padding-top: 20px;
  float: left;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.form-container h2 {
  font-size: 44px;
  color: white;
}

.form-container p {
  font-size: 24px;
  color: white;
  text-align: center;
  margin-top: 30px;
  width: 700px;
  line-height: 32px;
}

.form-container .form {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin-top: 50px;
}

.form-container .form input,
button,
textarea {
  padding: 20px;
  font-size: 16px;
  background: transparent;
  color: white;
}

.form-container .form button {
  background: #0095ff;
  border: 0px;
  color: white;
}

.form-container .form textarea {
  height: 200px;
}

.form-container .form .name-input {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 30px;
}

.form-container .form .name-input input {
  width: 100%;
}

/* ** Contact Form of Contact Page Design ends from here */

/* ** Otp Verification Page Design Starts from here */

.otpContainer {
  display: flex;
  width: 100%;
  background: none;
  float: left;
  height: 100vh;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.otpContainer form {
  width: 620px;
  padding: 50px;
  text-align: left;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 5px 0px #0095ff;
  border-radius: 10px;
  overflow: hidden;
}

.otpContainer h2 {
  color: white;
  font-size: 44px;
}

.otpContainer span {
  color: #0095ff;
  font-size: 28px;
}

.otpContainer .inputRow {
  display: flex;
  justify-content: space-between;
  margin: 30px 0px;
}

.otpContainer input {
  width: 80px;
  height: 80px;
  border-radius: 10px;
  background: none;
  text-align: center;
  font-size: 44px;
  color: white;
  -webkit-appearance: textfield;
  border: 1px solid white;
}

.otpContainer input::-webkit-outer-spin-button,
.otpContainer input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

a {
  color: white;
  font-size: 15px;
}

.otpContainer p {
  color: white;
  font-size: 18px;
}

.otpContainer a {
  color: #0095ff;
}

.otpContainer a:hover {
  color: #0089eb;
}

.otpContainer button {
  border-radius: 10px;
  background: #0095ff;
  border: none;
  cursor: pointer;
  margin-top: 10px;
}

.otpContainer button:hover {
  background: #0089eb;
}

/* ** Otp Verification Page Design ends from here */

@media screen and (max-width: 889px) {
  .form-container {
    padding: 50px 100px;
  }
}

@media screen and (max-width: 779px) {
  .form-container {
    padding: 50px;
  }
  .form-container p {
    width: 100%;
  }
}

@media screen and (max-width: 685px) {
  .otpContainer {
    height: max-content;
    padding: 50px;
  }

  .otpContainer form {
    width: 100%;
    padding: 30px;
  }

  .otpContainer input {
    width: 70px;
    height: 70px;
    font-size: 34px;
  }
}

@media screen and (max-width: 615px) {
  .otpContainer input {
    width: 60px;
    height: 60px;
  }
}

@media screen and (max-width: 550px) {
  .otpContainer form {
    padding: 0px;
    box-shadow: none;
  }
  .otpContainer input {
    width: 50px;
    height: 50px;
  }
}

@media screen and (max-width: 449px) {
  .form-container {
    padding: 20px;
  }
  .form-container .form {
    gap: 20px;
  }
  .form-container .form .name-input {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 20px;
  }
  .otpContainer {
    height: max-content;
    padding: 20px;
  }
}

.form-container {
  width: 100%;
  padding: 50px 200px;
  padding-top: 20px;
  float: left;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.form-container h2 {
  font-size: 44px;
  color: white;
}

.form-container p {
  font-size: 24px;
  color: white;
  text-align: center;
  margin-top: 30px;
  width: 700px;
  line-height: 32px;
}

.form-container .form {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin-top: 50px;
}

.form-container .form input,
button,
textarea {
  padding: 20px;
  font-size: 16px;
  background: transparent;
  color: white;
}

.form-container .form button {
  background: #0095ff;
  border: 0px;
  color: white;
}

.form-container .form textarea {
  height: 200px;
}

.form-container .form .name-input {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 30px;
}

.form-container .form .name-input input {
  width: 100%;
}

/* ** Contact Form of Contact Page Design ends from here */

/* ** Otp Verification Page Design Starts from here */

.inputRow{
  display: flex;
  justify-content: center;
  margin: 30px 0px;
  margin-top: 0px;
  gap: 10px;
}

.inputRow input{
  width: 70px;
  height: 70px;
  border-radius: 10px;
  background: none;
  text-align: center;
  font-size: 44px;
  color: white;
  -webkit-appearance: textfield;
  border: 1px solid white;
}

.inputRow input::-webkit-outer-spin-button,
.inputRow input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* ** Otp Verification Page Design ends from here */


/* ** Login Though Phone Number Page */

.phone-number{
  width: 100%;
  margin-bottom: 20px;
  border: 1px solid white;
  border-radius: 5px;
  padding: 8px;
}

.phone-number input{
  width: calc(100% - 40px) !important;
  margin-left: 40px !important;
  border: none !important;
  font-size: 18px !important;
}

.phone-number .flag-dropdown{
  background: none !important;
  border: none !important;
}

.phone-number .selected-flag{
  background: none !important;
  font-size: 18px !important;
}

.phone-number-dropdown{
  background: #0095ff !important;
  text-align: left;
}

.phone-number-dropdown span{
  color: white !important;
}

.phone-number-dropdown .search{
  z-index: 1;
  padding: 5px !important;
  background: #0095ff !important;
}

.phone-number-dropdown input{
  width: 100% !important;
  margin: 0px !important;
  color: #1b182b;
  padding: 8px !important;
  border: none;
}

.phone-number-dropdown li{
  display: flex;
  align-items: center;
  position: relative;
}

.phone-number-dropdown li:hover{
  background: #1b182b !important;
}

.phone-number-dropdown li.active, li.highlight{
  background: #1b182b !important;
}

/* ** Responsive Design */

@media screen and (max-width: 889px) {
  .form-container {
    padding: 50px 100px;
  }
  .inputRow input{
    width: 60px;
    height: 60px;
    font-size: 34px;
  }
}

@media screen and (max-width: 779px) {
  .form-container {
    padding: 50px;
  }
  .form-container p {
    width: 100%;
  }
}

@media screen and (max-width: 615px) {
  .inputRow input{
    width: 60px;
    height: 60px;
  }
}

@media screen and (max-width: 550px) {
  .inputRow input{
    width: 50px;
    height: 50px;
  }
}

@media screen and (max-width: 449px) {
  .form-container {
    padding: 20px;
  }
  .form-container .form {
    gap: 20px;
  }
  .form-container .form .name-input {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 20px;
 }
}