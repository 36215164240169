.imgtop{
    width: 30%;
    height: 30px;
    border-radius: 6px;
    float: left;
}
.item{
    float: left;
    color: white;
    padding-left: 6px;
    
}
.item1{
    font-size: 28px;
    font-weight: 600;
    color: white;
    
    float: left;
    margin-left: 5px;
}
.container1{
    padding: 20px !important;
    margin-top: 30px;
    padding: 10px;
    display: flex;
    line-height: 3;
    flex-direction: column !important;
}

.item2{
    border-bottom: 2px solid rgb(226, 215, 215);
}

.itemtxt{
    margin-top: 10px !important;
    color: white;
    line-height: 1;
}
.itemtext3{
    font-size: 24px !important;
    font-weight: 600;
    float: left;
    color: white;
}

.resourceimg{
    width: 50%;
    height: 90px;
    border-radius: 6px;
    float: left;
    margin-right: 15px;
}
.resourceText{
    font-size: 16px;
    color: white;
    margin-left: 20px;
    text-align: justify;
}
.reviewImg{
    width: 80px;
    height: 80px;
    border-radius: 50%;
}

.resourcetext{
    color: white;
    text-align: start;
}
.container2{
    margin-top: 10px !important;
    margin-bottom: 5px;
}

.reviewbox{
        border-radius: 50% 50%;
                width: 55%;
                    height: 80px;
}

.reviewh{
    float: left;
    color: white;
}
.btnReview{
    border: 1px solid whitesmoke    ;
    width: 50px;
    height: 25px;
}
#icon{
  font-size: 22px;
  color: white;
}
.reviewboxitem{
    align-items: end;
        display: flex !important;
        flex-direction: column !important;
        line-height: 4;
}

.itemreview{
    display:flex !important;
    flex-direction: column !important;
    align-items: center !important;
}

.reviewText{
    color: white;
    font-size: 24px;
    line-height: 1;
}
.reviewText2 {
    color: rgba(255, 255, 255, 0.7);
    font-size: 18px;
}
.reviewboxitem1{
    line-height: 2;
    display: flex !important;
    flex-direction: column !important;
    align-items: flex-start;

}

.reviewpppp{
    margin-bottom: 5px;
}
.cardmain{
            background-color: #1b182b  !important;
        
}

.main{
    margin-top: 20px;
}