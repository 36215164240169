.App {
  display: flex;
  justify-content: space-around;
  font-family: sans-serif;
  text-align: center;
}

.time {
  font-size: 22px;
}

.App>div:first-child {
  margin-right: 20px;
}

.App>div:nth-child(2) {
  margin-right: 20px;
}





.expired-notice {
  text-align: center;
  padding: 1rem;
  border: 1px solid #ebebeb;
  border-radius: 0.25rem;
}

.expired-notice>span {
  font-size: 1.5rem;
  font-weight: bold;
  color: red;
}

.expired-notice>p {
  font-size: 1.5rem;
}

.show-counter .countdown-link {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 1.75rem;
  padding: 0.5rem;
  border: 1px solid #ebebeb;
  border-radius: 0.25rem;
  text-decoration: none;
  color: #000;
}

.show-counter .countdown-link .colon-time {
  align-self: baseline;
}

.show-counter .countdown {
  line-height: 1.25rem;
  padding: 0 0.75rem 0 0.75rem;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.show-counter .countdown.danger {
  color: #ff0000;
}

.show-counter .countdown.normal {
  color: #fff
}

.show-counter .countdown>p {
  margin: 0;
  font-size: 20px;
}

.show-counter .countdown>span {
  text-transform: uppercase;
  font-size: 0.75rem;
  line-height: 1rem;
}