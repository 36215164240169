  .editor--class {
    background-color: transparent;
    padding: 0 0.5rem;
    border: 1px solid hsla(0, 0%, 100%, 0.3);
    font-size: 16px;
    height: 200px;
    color: #ccc;
    overflow-y: auto;
    width: 100%;
  }

  .editor--class .DraftEditor-root {
    height: auto !important;
  }

  .toolbar--class {
    border: 1px solid #ccc;
    display: none;
  }


  .override-select .css-1s2u09g-control {
    background-color: #1b182b !important;
    min-height: 50px !important;
    border-color: #605e6b !important;
  }

  .override-select .css-1pahdxg-control {
    background-color: #1b182b !important;
    min-height: 50px !important;
    border-color: #605e6b !important;
  }

  .override-select .css-319lph-ValueContainer .css-qc6sy-singleValue {
    color: #fff !important;
  }

  .override-select .css-319lph-ValueContainer .css-1pndypt-Input {
    color: #fff !important;
  }

  .css-14el2xx-placeholder {
    color: #fff !important;
  }

  .override-select .css-2613qy-menu {
    background-color: #1b182b !important;
  }

  .css-1hb7zxy-IndicatorsContainer .css-1okebmr-indicatorSeparator {
    background-color: transparent !important;
  }