.profileDescription .editor--class {
  background-color: #292e3a;
  padding: 0 0.5rem;
  border: 1px solid rgba(255, 255, 255, 0.1);
  font-size: 16px;
  height: 200px;
  color: #ccc;
  overflow-y: hidden;
  width: 100%;
  border-radius: 0 4px 4px 0;
}

.profileDescription .editor--class .DraftEditor-root {
  height: auto !important;
}

.profileDescription .editor--class:hover {
  border-color: #0095ff;
}

.profileDescription .toolbar--class {
  border: 1px solid #ccc;
  display: none;
}

.profileDescription .active-editor-class {
  background-color: #292e3a;
  padding: 0 0.5rem;
  border: 1px solid #0095ff;
  font-size: 16px;
  height: 200px;
  color: #ccc;
  overflow-y: hidden;
  width: 100%;
  border-radius: 0 4px 4px 0;
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 1px #0095ff;
}
