.amount-label {
  color: #fff;
  border: 2px solid #0095ff;
  padding: 20px 40px;
  cursor: pointer;
  border-radius: 4px;
}
.amount-label:hover {
  background: #0095ff;
}
.amount-label-active {
  background: #0095ff;
}
.main_donate_wrapper {
  background-image: url(https://staywobucket.s3.us-west-1.amazonaws.com/emailTemplateImages/disaster.jpg);
  position: fixed;
  top: 0;
  right: 0;
  width: 100vw;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  overflow-y: scroll;
}

.sub_donate_wrapper {
  display: flex;
  justify-content: flex-end;
}

.form_wrapper {
  width: 35vw;
  height: 100%;
  position: relative;
  background: #1b182b;
  margin-top: 10%;
  margin-right: 5%;
  display: flex;
  justify-content: start;
  padding: 16px;
  flex-direction: column;
  align-items: center;
  border-radius: 12px;
}
.radio_opt_container {
  height: 200px;
  margin: 15px 0px;
}

@media screen and (max-width: 999px) {
  .main_donate_wrapper {
    overflow-x: hidden;
  }
  .sub_donate_wrapper {
    justify-content: center;
  }
  .form_wrapper {
    width: 95vw;
    margin-right: 0;
    margin-top: 43%;
  }
  .css-wb57ya-MuiFormControl-root-MuiTextField-root {
    width: 90% !important;
  }
  .css-1ycslcz-MuiFormLabel-root-MuiInputLabel-root {
    font-size: 0.8rem !important;
  }
  .radio_opt_container {
    height: 300px;
  }
}
